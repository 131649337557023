<template>
    <div class="relative inline-flex flex-col w-full">
        <div class="btn w-full justify-between min-w-44 bg-gray text-gray-200" style="border-color: #6b7280; border-radius: 0" @click="toggleDropdown">
            <div class="flex flex-wrap w-full">
                <div class="flex-1 items-center text-gray-500" style="min-height: 36px">
                    <div
                        v-if="!localselected.length"
                        value=""
                        type="text"
                        :ref="'input'"
                        @input="updateInput($event)"
                        @blur="clearInput($event)"
                        class="bg-gray text-white w-full no-outline"
                        style="border: 0; padding-left: 5px"
                        :placeholder="Agents"
                    />
                    <input
                        v-else
                        value=""
                        type="text"
                        :ref="'input'"
                        @input="updateInput($event)"
                        @blur="clearInput($event)"
                        class="bg-gray text-white w-full no-outline"
                        style="border: 0; padding-left: 5px"
                    />
                </div>
            </div>
            <svg class="shrink-0 ml-1 fill-current text-gray-400" width="11" height="7" viewBox="0 0 11 7">
                <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z"></path>
            </svg>
        </div>
        <div
            class="z-10 absolute ztop-full l-0 w-full bg-gray border py-1.5 rounded shadow-lg overflow-y-scroll top-full max-h-42"
            style="border-color: #6b7280; border-radius: 0"
            v-show="dropdown_open"
        >
            <div class="font-medium text-sm text-gray-600">
                <div
                    v-for="option of combined_list"
                    :key="option"
                    tabIndex="0"
                    class="flex items-center w-full py-2 px-3 bg-gray-2-hover cursor-pointer text-white"
                    @click="select(option)"
                >
                    <img v-if="etype == 'roles' && getRoleIcon(option.id)" :src="getRoleIcon(option.id)" width="24" height="24" class="mr-2" />
                    <img v-if="etype == 'objects' && getObjectIcon(option.id)" :src="getObjectIcon(option.id)" width="24" height="24" class="mr-2" />
                    <span :style="`color: ${etype == 'roles' ? getRoleColor(option.id) : 'white'}`">{{ option.name ?? option }}</span>
                    <i v-if="option.selected" class="fas fa-check" style="right: 15px; position: absolute"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Badge from './badge.vue';

export default {
    name: 'multiselect',
    props: {
        channels: {
            Array,
        },
        roles: {
            Array,
        },
        emojis: {
            Array,
        },
        strings: {
            Array,
        },
        objects: {
            Array,
        },
        selected: {
            Array,
        },
        etype: {
            String,
        },
        ctype: {
            Array,
        },
    },
    components: {
        Badge,
    },
    data() {
        return {
            localselected: (this.selected ?? []).map(i => {
                const object = {id: i};
                object.selected = true;
                return object;
            }),
            localbucket: this.channels ?? this.roles ?? this.emojis ?? this.strings ?? this.objects,
            dropdown_open: false,
        };
    },
    computed: {
        combined_list() {
            return this.localbucket.map(i => {
                i.selected = false;
                if (this.etype == 'objects') i.selected = this.localselected.find(k => k.id == i.id).selected;
                return i;
            });
        },
    },
    methods: {
        toggleDropdown() {
            if (this.dropdown_open) {
                if (this.$refs['input'] == document.activeElement) return;
                this.dropdown_open = false;
            } else {
                if (this.$refs['input'].value) return this.updateInput();
                this.$refs['input'].focus();
                this.dropdown_open = true;
                /*
                if (this.etype == 'roles') this.localbucket = this.roles.filter(i => !this.localselected.includes(i.id)).sort((a, b) => b.rawPosition - a.rawPosition);
                if (this.etype == 'emojis') this.localbucket = this.emojis.filter(i => !this.localselected.includes(i.id));
                if (this.etype == 'channels' && !this.ctype) this.localbucket = this.channels.filter(i => !this.localselected.includes(i.id));
                if (this.etype == 'channels' && this.ctype)
                    this.localbucket = this.channels
                        .filter(i => this.ctype.includes(i.type) && !this.localselected.includes(i.id))
                        .sort((a, b) => a.rawPosition - b.rawPosition);
                if (this.etype == 'strings') this.localbucket = this.strings.filter(i => !this.localselected.includes(i));
                if (this.etype == 'objects') this.localbucket = this.objects.filter(i => !this.localselected.includes(i.id));
                */
            }
        },
        clearSingleTagDropDown(i) {
            this.localselected.splice(i, 1);
        },
        getName(id) {
            if (this.etype == 'roles') return this.roles.some(i => i.id == id) ? this.roles.find(i => i.id == id).name : `@${id}`;
            if (this.etype == 'emojis') return this.emojis.some(i => i.id == id) ? this.emojis.find(i => i.id == id).name : `${id}`;
            if (this.etype == 'channels') return this.channels.some(i => i.id == id) ? `#${this.channels.find(i => i.id == id).name}` : `#${id}`;
            if (this.etype == 'strings') return this.strings.find(i => i == id);
            if (this.etype == 'objects') return this.objects.find(i => i.id == id).name;
        },
        getRoleColor(id) {
            return this.roles.find(i => i.id == id)?.color
                ? `#${this.roles
                      .find(i => i.id == id)
                      .color.toString(16)
                      .padStart(6, '0')}`
                : '#fff';
        },
        getRoleIcon(id) {
            const role = this.roles.find(i => i.id == id);
            return role?.icon ? `https://cdn.discordapp.com/role-icons/${role.id}/${role.icon}.png` : null;
        },
        getObjectIcon(id) {
            return this.objects.find(i => i.id == id).icon ?? null;
        },
        getEmojiIcon(id) {
            const emoji = this.emojis.find(i => i.id == id);
            return emoji?.icon ? `https://cdn.discordapp.com/emojis/${emoji.id}/${emoji.icon}.png` : null;
        },
        clearInput(e) {
            if (e.relatedTarget && e.relatedTarget.tagName == 'DIV') return;
            this.dropdown_open = false;
        },
        updateInput(e) {
            if (!this.dropdown_open && this.$refs['input'].value) {
                this.dropdown_open = true;
                this.$refs['input'].focus();
            }
            if (this.etype == 'roles') {
                this.localbucket = this.roles.filter(
                    item => item.name.toLowerCase().includes(this.$refs['input'].value.toLowerCase()) && !this.localselected.includes(item.id)
                );
                if (!this.localbucket.length && this.$refs['input'].value.length >= 17 && this.$refs['input'].value.length <= 19 && !isNaN(this.$refs['input'].value))
                    this.localbucket.push({
                        id: this.$refs['input'].value,
                        icon: null,
                        name: `Role/User mit ID: ${this.$refs['input'].value}`,
                        color: 16777215,
                    });
            }
            if (this.etype == 'emojis') {
                this.localbucket = this.emojis.filter(
                    item => item.name.toLowerCase().includes(this.$refs['input'].value.toLowerCase()) && !this.localselected.includes(item.id)
                );
                if (!this.localbucket.length && this.$refs['input'].value.length >= 17 && this.$refs['input'].value.length <= 19 && !isNaN(this.$refs['input'].value))
                    this.localbucket.push({
                        id: this.$refs['input'].value,
                        icon: null,
                        name: `Emoji mit der ID: ${this.$refs['input'].value}`,
                    });
            }
            if (this.etype == 'channels')
                this.localbucket = this.channels.filter(
                    item =>
                        this.ctype.includes(item.type) &&
                        item.name.toLowerCase().includes(this.$refs['input'].value.toLowerCase()) &&
                        !this.localselected.includes(item.id)
                );
            if (this.etype == 'strings')
                this.localbucket = this.strings.filter(
                    item => item.toLowerCase().includes(this.$refs['input'].value.toLowerCase()) && !this.localselected.includes(item)
                );
            if (this.etype == 'objects')
                this.localbucket = this.objects.filter(i => i.name.toLowerCase().includes(this.$refs['input'].value.toLowerCase()) && !this.localselected.includes(i.id));
        },
        clearTagDropDown() {
            this.localselected = [];
        },
        select(option) {
            this.localbucket.find(i => i.id == option.id).selected = option.selected ? false : true;
            //this.dropdown_open = false;
            this.$refs['input'].value = '';
        },
        values() {
            return this.localselected;
        },
    },
};
</script>
