<template>
    <div class="dark font-inter antialiased bg-white text-white bg-gray-900 dark:text-gray-100 tracking-tight">
        <div class="flex flex-col min-h-screen overflow-hidden">
            <nav-bar />
            <main class="grow">
                <section class="background flex items-center justify-center">
                    <div class="max-w-7xl mx-auto px-4 sm:px-6">
                        <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold">VALO ANALYTICS</h1>
                        <p class="text-xl text-gray-600 dark:text-gray-400 aos-init aos-animate" data-aos="fade-down" data-aos-delay="150">
                            Get the most detailed VALORANT Statistics out of <a style="color: cyan">{{ analyzed_matches.toLocaleString() }}</a> Games
                        </p>
                    </div>
                </section>
                <section id="stats">
                    <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                        <h2 class="h2 font-red-hat-display mb-4">Stats</h2>
                        <div class="flex items-center justify-center"></div>
                    </div>
                    <div class="max-w-7xl mx-auto px-4 sm:px-6" v-if="!loading">
                        <div class="flex flex-wrap mt-1 mb-32" style="justify-content: space-evenly">
                            <div>
                                <p class="text-l text-white mb-2" style="text-align: left">Agents</p>
                                <multi-select :etype="'objects'" :objects="agents" ref="agent_select" :selected="agents.map(i => i.id)" :label="'Agents'"></multi-select>
                            </div>
                            <div>
                                <p class="text-l text-white mb-2" style="text-align: left">Maps</p>
                                <multi-select :etype="'objects'" :objects="maps" ref="agent_select" :selected="maps.map(i => i.id)" :label="'Maps'"></multi-select>
                            </div>
                            <div>
                                <p class="text-l text-white mb-2" style="text-align: left">Tiers</p>
                                <multi-select :etype="'objects'" :objects="tiers" ref="agent_select" :selected="tiers.map(i => i.id)" :label="'Tiers'"></multi-select>
                            </div>
                        </div>
                        <table class="table-auto w-full">
                            <thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-badge border-t border-b">
                                <tr>
                                    <th class="px-2 py-3 vertical-align-middle">
                                        <div class="font-semibold text-center text-white">Agent</div>
                                    </th>
                                    <th class="px-2 py-3 vertical-align-middle">
                                        <div class="font-semibold text-center text-white">Wins</div>
                                    </th>
                                    <th class="px-2 py-3 vertical-align-middle">
                                        <div class="font-semibold text-center text-white">Win %</div>
                                    </th>
                                    <th class="px-2 py-3 vertical-align-middle">
                                        <div class="font-semibold text-center text-white">Draws</div>
                                    </th>
                                    <th class="px-2 py-3 vertical-align-middle">
                                        <div class="font-semibold text-center text-white">Total</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="text-sm" v-if="loading">
                                <tr v-for="stat of base" :key="stat">
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center text-gray-800">
                                            <div class="w-9 h-10 shrink-0 flex items-center justify-center mr-2 sm:mr-3">
                                                <Skeleton height="40px" width="40px" loading></Skeleton>
                                            </div>
                                            <Skeleton height="25px" width="150px" loading></Skeleton>
                                        </div>
                                    </td>
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center justify-center">
                                            <Skeleton height="25px" width="150px" loading></Skeleton>
                                        </div>
                                    </td>
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center justify-center">
                                            <Skeleton height="25px" width="150px" loading></Skeleton>
                                        </div>
                                    </td>
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center justify-center">
                                            <Skeleton height="25px" width="150px" loading></Skeleton>
                                        </div>
                                    </td>
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center justify-center">
                                            <Skeleton height="25px" width="150px" loading></Skeleton>
                                        </div>
                                    </td>
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center justify-center">
                                            <Skeleton height="25px" width="150px" loading></Skeleton>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="text-sm" v-else>
                                <tr v-for="stat of base" :key="stat">
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center justify-center">
                                            <div class="w-9 h-10 shrink-0 flex items-center justify-center mr-2 sm:mr-3">
                                                <img class="ml-1" :src="`https://media.valorant-api.com/agents/${stat.agent}/displayicon.png`" width="20" height="20" />
                                            </div>
                                            <div class="font-medium text-white">{{ agents.find(i => i.id == stat.agent)?.name ?? 'Unknown' }}</div>
                                        </div>
                                    </td>
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center justify-center">
                                            <div class="font-medium text-white">{{ stat.wins.toLocaleString() }}</div>
                                        </div>
                                    </td>
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center justify-center">
                                            <div class="font-medium text-white">{{ winPerc(stat.wins, stat.total) }}%</div>
                                        </div>
                                    </td>
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center justify-center">
                                            <div class="font-medium text-white">{{ stat.draws.toLocaleString() }}</div>
                                        </div>
                                    </td>
                                    <td class="px-2 py-3 vertical-align-middle">
                                        <div class="flex items-center justify-center">
                                            <div class="font-medium text-white">{{ stat.total.toLocaleString() }}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </main>
            <footer class="relative">
                <div class="max-w-6xl mx-auto px-4 sm:px-6">
                    <div class="py-12 md:py-16 border-t border-gray-200 dark:border-gray-800 -mt-px">
                        <!-- Footer illustration -->
                        <div class="pointer-events-none -z-1" aria-hidden="true">
                            <svg
                                class="absolute bottom-0 left-0 transform -translate-x-1/2 ml-24 dark:opacity-40"
                                width="800"
                                height="264"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="400" cy="400" r="400" fill="url(#footerglow_paint0_radial)" fill-opacity=".4"></circle>
                                <defs>
                                    <radialGradient
                                        id="footerglow_paint0_radial"
                                        cx="0"
                                        cy="0"
                                        r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="rotate(90 0 400) scale(315.089)"
                                    >
                                        <stop stop-color="#3ABAB4"></stop>
                                        <stop offset="1" stop-color="#3ABAB4" stop-opacity=".01"></stop>
                                    </radialGradient>
                                </defs>
                            </svg>
                        </div>

                        <!-- Top area: Blocks -->
                        <div class="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
                            <!-- 1st block -->
                            <div class="md:col-span-2 lg:col-span-3">
                                <!-- Logo -->
                                <img src="../assets/ServerYellowTransparent.png" class="w-12 h-12" />
                            </div>
                        </div>

                        <!-- Bottom area -->
                        <div class="md:flex md:items-center md:justify-between">
                            <!-- Social links -->
                            <ul class="flex mb-4 md:order-2 md:mb-0">
                                <li>
                                    <a
                                        class="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                                        href="https://twitter.com/PlayValoDE"
                                        aria-label="Twitter"
                                    >
                                        <div class="flex items-center justify-center w-8 h-8 fill-current">
                                            <i class="tooltip fab fa-twitter cl-gradient"></i>
                                        </div>
                                    </a>
                                </li>
                                <li class="ml-4">
                                    <a
                                        class="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                                        href="https://github.com/VALO-DE"
                                        aria-label="Github"
                                    >
                                        <div class="flex items-center justify-center w-8 h-8 fill-current">
                                            <i class="tooltip fab fa-github cl-gradient"></i>
                                        </div>
                                    </a>
                                </li>
                                <li class="ml-4">
                                    <a
                                        class="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                                        href="https://www.tiktok.com/@playvalode"
                                        aria-label="TiKkok"
                                    >
                                        <div class="flex items-center justify-center w-8 h-8 fill-current">
                                            <i class="tooltip fab fa-tiktok cl-gradient"></i>
                                        </div>
                                    </a>
                                </li>
                                <li class="ml-4">
                                    <a
                                        class="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                                        href="https://www.instagram.com/playvalode/"
                                        aria-label="Instagram"
                                    >
                                        <div class="flex items-center justify-center w-8 h-8 fill-current">
                                            <i class="tooltip fab fa-instagram cl-gradient"></i>
                                        </div>
                                    </a>
                                </li>
                                <li class="ml-4">
                                    <a
                                        class="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                                        href="https://www.twitch.tv/playvalode"
                                        aria-label="Twitch"
                                    >
                                        <div class="flex items-center justify-center w-8 h-8 fill-current">
                                            <i class="tooltip fab fa-twitch cl-gradient"></i>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<style src="@/assets/style.css"></style>
<style scoped>
.background {
    height: 50vh;
}
.background {
    overflow: auto;
    position: relative;
}
</style>

<script>
import NavBar from '../components/navbar.vue';
import axios from 'axios';
import MultiSelect from '../components/multiselect.vue';

export default {
    name: 'Home',
    components: {
        NavBar,
        MultiSelect,
    },
    data() {
        return {
            loading: true,
            analyzed_matches: 0,
            base: [],
            agents: [],
            maps: [],
            tiers: [],
        };
    },
    async created() {
        const requests = [
            axios.get('https://backend.match-db.manuel-hexe.de/analyzed_matches').catch(e => e),
            axios.get('https://backend.match-db.manuel-hexe.de/rpc/get_single_character_winrates').catch(e => e),
            axios.get('https://backend.match-db.manuel-hexe.de/character').catch(e => e),
            axios.get('https://backend.match-db.manuel-hexe.de/map').catch(e => e),
            axios.get('https://backend.match-db.manuel-hexe.de/tier').catch(e => e),
        ];
        const requests_resolved = await Promise.all(requests);
        this.analyzed_matches = requests_resolved[0].data.find(i => i.name == 'BestAgentComboAnalyzer').count_estimate;
        this.base = requests_resolved[1].data;
        this.agents = requests_resolved[2].data.map(i => {
            i.id = i.uuid;
            i.icon = `https://media.valorant-api.com/agents/${i.uuid}/displayicon.png`;
            return i;
        });
        console.log(this.agents.map(i => i.id));
        this.maps = requests_resolved[3].data.map(i => {
            i.id = i.uuid;
            return i;
        });
        this.tiers = requests_resolved[4].data.map(i => {
            i.name = i.patched_tier;
            i.icon = `https://media.valorant-api.com/competitivetiers/03621f52-342b-cf4e-4f86-9350a49c6d04/${i.tier}/smallicon.png`;
            return i;
        });
        this.loading = false;
    },
    methods: {
        winPerc(win, total) {
            return ((win / total) * 100).toFixed(2);
        },
    },
};
</script>
