<template>
    <header class="absolute w-full z-30">
        <div class="w-full px-4 sm:px-6">
            <div class="flex items-center justify-between h-20">
                <!-- Desktop navigation -->
                <nav class="hidden md:flex md:grow">
                    <!-- Desktop menu links -->
                    <ul class="flex grow flex-wrap items-center font-medium justify-center">
                        <li>
                            <a
                                class="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
                                @click="scrollto('statistics')"
                                >Statistics</a
                            >
                        </li>
                        <li>
                            <a
                                class="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
                                href="https://discord.gg/b5FmTqG"
                                >Discord</a
                            >
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'navbar',
    props: {
        image: {
            String,
        },
    },
    methods: {
        scrollto(element) {
            document.getElementById(element).scrollIntoView();
        },
    },
};
</script>
