<template>
    <div class="badge_parent cursor-pointer" :style="'background-color: ' + (background ?? '#262a2b')">
        <div v-if="image" class="badge_image">
            <img :src="image" width="24" height="24" />
        </div>
        <div class="badge_text" :style="'color: ' + color ?? pickTextColorBasedOnBgColorSimple(background ?? '#262a2b', '#ffffff', '#000000')">{{ label }}</div>
    </div>
</template>

<style>
.badge_parent {
    display: flex;
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
    background-color: #262a2b;
    align-items: center;
    color: white;
    margin-right: 0.25em;
    text-align: center;
}
.badge_image {
    margin-right: 4px;
}
.badge_text {
    font-weight: 700;
}
</style>

<script>
export default {
    name: 'badge',
    props: {
        image: {
            String,
        },
        label: {
            String,
        },
        background: {
            String,
        },
        color: {
            String,
        },
    },
    data() {
        return {};
    },
    methods: {
        pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
            if (!bgColor) return;
            const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
            const r = parseInt(color.substring(0, 2), 16); // hexToR
            const g = parseInt(color.substring(2, 4), 16); // hexToG
            const b = parseInt(color.substring(4, 6), 16); // hexToB
            return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
        },
    },
};
</script>
